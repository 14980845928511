'use client';
import { ComponentPropsWithoutRef, ReactNode } from 'react';
import { DirectionProvider as RadixDirectionProvider } from '@radix-ui/react-direction';

type Props = {
  dir: ComponentPropsWithoutRef<typeof RadixDirectionProvider>['dir'];
  children: ReactNode;
};

const DirectionProvider = ({ dir, children }: Props) => {
  return <RadixDirectionProvider dir={dir}>{children}</RadixDirectionProvider>;
};

export default DirectionProvider;
